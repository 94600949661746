<template>
    <div class="sale-log">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
export default {
    name: 'SaleLog',
    props: {},
    components: {},
    data() {
        return {}
    },
    watch: {},
    created() {
        this.setHeaderTitle('日志')
    },
    mounted() { },
    methods: {},
    beforeDestroy() { }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
